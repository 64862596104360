import type { Lang } from "@/types";
import { useMemo, type ReactNode } from "react";
import { useTranslations } from "@/i18n";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { EyeIcon, ShoppingBagIcon } from "lucide-react";
import type { CatalogProduct } from "../../types";
import { ProductGroupOptions } from "../../shared/ProductGroupOptions";
import type { Supplier } from "@/services/supplier";
import { ImageWithFallback } from "@/components/ImageWithFallback";
import { OffersTable, OffersPopover } from "../../offers/Offers";
import { ProductPrice } from "../../shared/ProductPrice";
import { formatDate } from "@/lib/format-date";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ProductConsumer } from "@/components/shared/ProductConsumer";

export type ProductCardProps = {
  product: CatalogProduct;
  setProduct: (product: CatalogProduct) => void;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

type ProductCardsProps = {
  lang: Lang;
  country: string;
  supplier: Supplier;
  cards: ProductCardProps[];
  viewMode: string;
};

export const ProductCards = ({
  cards,
  lang,
  country,
  supplier,
  viewMode,
}: ProductCardsProps) => {
  const nodes = useMemo(
    () => cards.map((card) => getCardNodes(card, { lang, country, supplier })),
    [viewMode, cards],
  );

  if (viewMode === "list") {
    return <ProductCardsForList cards={nodes} lang={lang} />;
  }

  return <ProductCardsForGrid cards={nodes} lang={lang} />;
};

type ProductCardUIProps = {
  product: CatalogProduct;
  imageNode: ReactNode;
  buttonsNode: ReactNode;
  nameNode: ReactNode;
  brandNode: ReactNode;
  kitProductsNode: ReactNode;
  optionsNode: ReactNode;
  detailsNode: ReactNode;
  listAttributesNode: ReactNode;
  gridAttributesNode: ReactNode;
  priceNode: ReactNode;
  groupOptionsNode: ReactNode;
};

const ProductCardsForList = ({
  cards,
  lang,
}: {
  cards: ProductCardUIProps[];
  lang: Lang;
}) => {
  const t = useTranslations(lang);
  return (
    <div
      className={cn(
        "grid gap-4",
        "[&>*]:col-span-3 grid-cols-[120px_1fr_auto]",
        "sm:[&>*]:col-span-4 sm:grid-cols-[100px_repeat(3,auto)] md:grid-cols-[120px_repeat(3,auto)]",
        "lg:[&>*]:col-span-5 lg:grid-cols-[140px_1fr_1fr_1fr_auto] xl:grid-cols-[160px_repeat(3,1fr)_auto]",
      )}
    >
      {cards.map((card) => (
        <Card
          key={card.product.id}
          className={cn(
            "grid grid-cols-subgrid gap-x-2 md:gap-x-8 lg:gap-x-4 xl:gap-8 gap-y-1 p-2",
            "grid-flow-col grid-rows-[repeat(3,auto)]",
            "sm:grid-rows-1 sm:items-center",
          )}
        >
          <div className="max-sm:row-span-3">{card.imageNode}</div>
          <div>
            {card.brandNode}
            {card.nameNode}
            {card.kitProductsNode}
            {card.optionsNode}
            <div className="hidden sm:block">{card.priceNode}</div>
          </div>
          <div className="">
            <p className="hidden sm:block text-sm text-one font-bold">
              {t("common.details")}
            </p>
            {card.detailsNode}
          </div>
          <div className="hidden lg:block ">
            {card.groupOptionsNode ?? (
              <>
                {card.listAttributesNode && (
                  <p className="text-sm text-one font-bold">
                    {t("common.attributes")}
                  </p>
                )}
                {card.listAttributesNode}
              </>
            )}
          </div>
          <div className="sm:hidden">{card.priceNode}</div>
          <div className="-col-end-1 max-sm:row-span-3 max-sm:*:flex-col-reverse">
            {card.buttonsNode}
          </div>
        </Card>
      ))}
    </div>
  );
};

const ProductCardsForGrid = ({
  cards,
}: {
  cards: ProductCardUIProps[];
  lang: Lang;
}) => {
  return (
    <div
      className={cn(
        "grid gap-x-2 gap-y-4 md:gap-4 xl:gap-4",
        "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5",
        "grid-rows-[repeat(6,auto)] [&>*]:row-span-6",
      )}
    >
      {cards.map((card) => (
        <Card
          key={card.product.id}
          className="grid gap-1 md:gap-2 p-2 grid-rows-subgrid"
        >
          <div>{card.imageNode}</div>
          <div className="leading-tight grid md:gap-1 self-start">
            {card.brandNode}
            {card.nameNode}
            {card.kitProductsNode}
          </div>
          {!card.groupOptionsNode && <div>{card.optionsNode}</div>}
          <div className="flex flex-col gap-2">
            <div>{card.detailsNode}</div>
            <div>{card.gridAttributesNode}</div>
          </div>
          {card.groupOptionsNode && <div>{card.groupOptionsNode}</div>}
          <div>{card.priceNode}</div>
          <div>{card.buttonsNode}</div>
        </Card>
      ))}
    </div>
  );
};

const getCardNodes = (
  { product, setProduct, onPrimaryAction, onSecondaryAction }: ProductCardProps,
  { lang, supplier }: { lang: Lang; country: string; supplier: Supplier },
) => {
  const t = useTranslations(lang);

  const imageNode = (
    <div className="relative">
      <a href={`/${lang}/products/${product.slug}`}>
        <ImageWithFallback
          src={product.image}
          alt={product.name}
          width={280}
          height={280}
          loading="lazy"
          className={cn("block aspect-square w-full object-cover rounded-lg")}
          style={{ viewTransitionName: `product-image-${product.id}` }}
        />
      </a>
      {product.hasOffer && (
        <div className="absolute top-0 right-0">
          <OffersPopover lang={lang}>
            <OffersTable product={product} lang={lang} supplier={supplier} />
          </OffersPopover>
        </div>
      )}
    </div>
  );

  const brandNode = (
    <p className="text-xs md:text-sm text-muted-foreground">
      {product.brand ?? "-"}
    </p>
  );

  const nameNode = (
    <h3 className="text-sm lg:text-base md:leading-tight text-one text-pretty font-semibold">
      <a href={`/${lang}/products/${product.slug}`} className="hover:underline">
        {product.name}
      </a>
    </h3>
  );

  const kitProductsNode =
    product.type === "KIT" ? (
      <div className="text-xs text-one flex items-center gap-2 py-1">
        {product.kitProducts.map(({ id, quantity }) => (
          <ProductConsumer key={id} id={id} supplier={supplier} lang={lang}>
            {(product) => (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div className="flex items-center gap-1">
                      <ImageWithFallback
                        src={product.image}
                        className="size-6 inline-block rounded-md border"
                        alt={product.name}
                      />
                      <span>x{quantity}</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <a
                      href={`/${lang}/products/${id}`}
                      target="_blank"
                      className="flex gap-2 items-center"
                    >
                      <ImageWithFallback
                        src={product.image}
                        className="size-12 inline-block rounded-md border"
                        alt={product.name}
                      />
                      <div>
                        <p className="text-xs md:text-sm text-muted-foreground">
                          {product.brand ?? "-"}
                        </p>
                        <a
                          href={`/${lang}/products/${product.slug}`}
                          className="hover:underline"
                          target="_blank"
                        >
                          {product.name}
                        </a>
                      </div>
                      <span className="text-two">(x{quantity})</span>
                    </a>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </ProductConsumer>
        ))}
      </div>
    ) : null;

  const optionsNode = (
    <ul className="flex flex-wrap gap-1 text-xs lg:text-sm text-two leading-none">
      {Object.entries(product.options).map(([key, value]) => (
        <li key={key} className="capitalize">
          {key}: <b>{value}</b>
        </li>
      ))}
    </ul>
  );

  const priceNode = (
    <p className="order-last md:order-none font-bold xl:text-lg">
      <ProductPrice supplier={supplier} product={product} lang={lang} />
    </p>
  );

  const buttonsNode = (
    <div className={cn("flex gap-2 flex-wrap-reverse")}>
      <div className="flex-1">
        <Button
          onClick={onSecondaryAction}
          variant="outline"
          className="w-full flex gap-2"
        >
          <EyeIcon className="w-4 h-4" />
          <span className="hidden md:inline">{t("common.see")}</span>
        </Button>
      </div>

      <div className="flex-1">
        <Button
          variant="one"
          onClick={onPrimaryAction}
          disabled={!product.addable}
          className="w-full flex gap-2"
        >
          <ShoppingBagIcon className="w-4 h-4" />
          <span className="hidden md:inline">
            {!product.addable ? t("common.soldOut") : t("common.add")}
          </span>
        </Button>
      </div>
    </div>
  );

  const detailsNode = (
    <div className="text-xs text-one md:row-span-2">
      {product.details.unitPrice && (
        <p>
          {t("products.fields.unitPrice")}: {product.details.unitPrice}
        </p>
      )}
      {product.details.upc && (
        <p>
          {t("products.fields.upc")}:{" "}
          <span>
            {product.details.upc.slice(0, 20)}
            {product.details.upc.length > 20 && "..."}
          </span>
        </p>
      )}
      {product.details.dueDate && (
        <p>
          {t("products.fields.dueDate")}:{" "}
          {formatDate(product.details.dueDate, lang)}
        </p>
      )}
    </div>
  );

  const groupOptionsNode = supplier.marketplaceIsGrouped ? (
    <ProductGroupOptions
      lang={lang}
      product={product}
      supplier={supplier}
      onSelect={({ target }) => {
        target && setProduct(target);
      }}
      size="sm"
    />
  ) : null;

  const listAttributesNode = Object.values(product.attributes).some(
    (attr) => attr.catalogListVisible,
  ) && (
    <ul className="text-xs text-card-foreground md:row-span-2">
      {Object.entries(product.attributes)
        .filter(([, attr]) => attr.catalogListVisible)
        .map(([key, { value }]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
    </ul>
  );

  const gridAttributesNode = Object.values(product.attributes).some(
    (attr) => attr.catalogGridVisible,
  ) && (
    <ul className="text-xs text-card-foreground md:row-span-2">
      {Object.entries(product.attributes)
        .filter(([, attr]) => attr.catalogGridVisible)
        .map(([key, { value }]) => (
          <li key={key}>
            {key}: {value}
          </li>
        ))}
    </ul>
  );

  const nodes: ProductCardUIProps = {
    product,
    imageNode,
    buttonsNode,
    nameNode,
    brandNode,
    kitProductsNode,
    optionsNode,
    groupOptionsNode,
    detailsNode,
    listAttributesNode,
    gridAttributesNode,
    priceNode,
  };

  return nodes;
};
