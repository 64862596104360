import { Button, buttonVariants } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useTranslations } from "@/i18n";
import type { CatalogLocals } from "@/types";
import { useStore } from "@nanostores/react";
import { Loader2Icon, ShoppingBagIcon } from "lucide-react";
import { $cart, $cartOpen } from "./cart.store";
import { cn } from "@/lib/utils";
import { CartItems } from "./CartItems";
import { CartSummary } from "./CartSummary";
import { usePrepurchase } from "@/hooks/use-prepurchase";
import { ProductsCarousel } from "../products/shared/ProductsCarousel";
import { useQuote } from "@/hooks/use-quote";

export const CartSheet = ({ locals }: { locals: CatalogLocals }) => {
  const open = useStore($cartOpen);

  return (
    <Sheet open={open} onOpenChange={$cartOpen.set}>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon">
          <ShoppingBagIcon className="w-4 h-4" />
        </Button>
      </SheetTrigger>
      <SheetContent
        className="flex flex-col w-5/6 sm:max-w-md"
        aria-describedby={undefined}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <CartSheetContent locals={locals} />
      </SheetContent>
    </Sheet>
  );
};

const CartSheetContent = ({ locals }: { locals: CatalogLocals }) => {
  const { lang, supplier } = locals;
  const t = useTranslations(lang);
  const cart = useStore($cart);
  const { loading } = usePrepurchase({ supplier });
  const { isQuote } = useQuote(supplier);

  return (
    <>
      <SheetHeader>
        <SheetTitle className="flex gap-2 items-center">
          {t("cart.title")}
          {loading && <Loader2Icon className="animate-spin w-4 h-4" />}
        </SheetTitle>
      </SheetHeader>

      <div className="overflow-y-auto -mx-6 px-6 pb-6">
        <CartItems supplier={supplier} lang={lang} />
        <br />
        <ProductsCarousel
          locals={locals}
          variant="small"
          heading={t("products.detail.featured.section.title")}
          noArrows
          categoryIds={cart.map((item) => item.product.categoryId)}
          featured={cart.length === 0 ? true : undefined}
          sort={{ field: cart.length > 0 ? "random" : "name", sort: "desc" }}
          omitProductIds={cart.map((item) => item.product.id)}
        />
      </div>

      {cart.length > 0 && (
        <div className="mt-auto flex flex-col">
          <div className="pb-4">
            <CartSummary lang={lang} supplier={supplier} />
          </div>
          <div className="flex">
            <a
              href={`/${lang}/checkout`}
              onClick={() => $cartOpen.set(false)}
              className={cn(buttonVariants({ variant: "one" }), "w-full")}
            >
              {isQuote ? t("common.quote") : t("cart.checkout")}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
