import { api } from "@/core/axios";

export const getProductsPDF = (params: ProductsPDFQueryParams) => {
  return api.get("/api/seeri-pdf/catalog-products", {
    params,
    responseType: "blob",
  });
};

type ProductsPDFQueryParams = {
  name?: string;
  supplierId: string;
  hasStock?: boolean;
  brandId: string;
  categoryId: string;
  segmentId?: string;
};
