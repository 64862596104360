import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, buttonVariants } from "@/components/ui/button";
import { DownloadCloudIcon, Loader2Icon } from "lucide-react";
import { useState, type ReactNode } from "react";
import type { ProductsQueryParams } from "./types";
import { cn } from "@/lib/utils";
import type { Supplier } from "@/services/supplier";
import { useAccount } from "@/hooks/use-account";
import { useTranslations } from "@/i18n";
import type { Lang } from "@/types";
import { getProductsPDF } from "@/services/products-pdf";
import { saveFile } from "@/lib/save-file";

export const ProductsPDF = ({
  supplier,
  query,
  filters,
  lang,
}: {
  query: ProductsQueryParams;
  filters: ReactNode;
  supplier: Supplier;
  lang: Lang;
}) => {
  const [downloading, setDownloading] = useState(false);
  const { account } = useAccount();
  const t = useTranslations(lang);

  const download = async () => {
    if (downloading) return;
    setDownloading(true);
    try {
      const res = await getProductsPDF({
        name: query.q ?? undefined,
        supplierId: supplier.id,
        hasStock: query.stock === "all" ? undefined : query.stock === "yes",
        brandId: query.brands,
        categoryId: query.categories,
        segmentId: account?.suppliers.find((s) => s.supplierId === supplier.id)
          ?.segments[0]?.id,
      });
      await saveFile(res.data, "catalog.pdf");
    } catch (error) {
      console.log("PDF Error", error);
      toast.error(t("common.errorOcurred"));
    }
    setDownloading(false);
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline" className="gap-2">
          <DownloadCloudIcon className="w-4 h-4" />
          PDF
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("products.pdf.downloadPDF")}</AlertDialogTitle>
          <AlertDialogDescription>
            {t("products.pdf.filterAccordingYourNeeds")}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div
          className={cn("flex flex-col gap-2 items-stretch", {
            "pointer-events-none opacity-50": downloading,
          })}
        >
          {filters}
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel disabled={downloading} className="flex-1">
            {t("common.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={(e) => {
              e.preventDefault();
              download();
            }}
            disabled={downloading}
            className={cn(
              buttonVariants({ variant: "one" }),
              "flex-1 flex gap-2",
            )}
          >
            {downloading && <Loader2Icon className="w-4 h-4 animate-spin" />}
            <span>
              {downloading ? t("common.downloading") : t("common.download")}
            </span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
