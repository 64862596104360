import { ProductCards } from "./ProductCards";
import { Button } from "@/components/ui/button";
import {
  FilterIcon,
  GridIcon,
  ListIcon,
  Loader2Icon,
  PackageIcon,
  // TicketPercentIcon,
} from "lucide-react";
import { useTranslations } from "@/i18n";
import { SelectMultiple } from "@/components/controls/SelectMultiple";
import { ComboBoxResponsive } from "@/components/controls/ComboBoxResponsive";
import type { ProductsQueryParams } from "../types";
import { ProductsUIContext, useProductsUIData, type ProductsUIData } from "./ProductsUI.context";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
} from "@/components/ui/sheet";
import { tabsListClassName, tabsTriggerClassName } from "@/components/ui/tabs";
import { ProductsPDF } from "../ProductsPDF";
import { cn } from "@/lib/utils";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useAccount } from "@/hooks/use-account";

export const ProductsUI = (props: ProductsUIData) => {
  return (
    <ProductsUIContext.Provider value={props}>
      <ProductsUIInner />
    </ProductsUIContext.Provider>
  );
};

const ProductsUIInner = () => {
  const { ready } = useAccount();
  const { filtersMode, viewMode, lang, supplier, productCards, initialLoading } =
    useProductsUIData();
  const t = useTranslations(lang);

  return (
    <div
      className={cn("grid gap-4 xl:gap-8 items-start", {
        "lg:grid-cols-[240px,1fr]": filtersMode === "sidebar",
      })}
    >
      {filtersMode === "sidebar" && (
        <div className="max-lg:hidden sticky top-20 xl:top-28">
          <div className="max-w-sm grid gap-4">
            <FiltersControls embedded />
          </div>
        </div>
      )}

      <div className="grid gap-4">
        <Topbar />
        {ready && !initialLoading && productCards.length === 0 && (
          <div className="p-6 text-center border rounded-lg">
            <h3 className="font-semibold text-lg">{t("common.noResults")}</h3>
            <a href={`/${lang}`} className="underline">
              {t("common.goBack")}
            </a>
          </div>
        )}
        <ProductCards
          cards={productCards}
          lang={lang}
          country={supplier.countryCode.toUpperCase()}
          supplier={supplier}
          viewMode={viewMode}
        />
        <Footer />
      </div>
    </div>
  );
};

const Topbar = () => {
  const { filtersMode, lang, supplier, query } = useProductsUIData();
  const hasFilters = filtersMode === "topbar";

  return (
    <div
      className={cn("grid grid-cols-[1fr_auto_1fr] gap-2", {
        "lg:grid-cols-[1fr_auto_auto] xl:grid-cols-[1fr_auto_1fr]": hasFilters,
        "lg:grid-cols-[auto_1fr]": !hasFilters,
      })}
    >
      {hasFilters && (
        <div className="hidden lg:flex gap-2 items-center">
          <FiltersControls />
        </div>
      )}

      <div className="lg:hidden">
        <ProductsSheet />
      </div>

      <div>
        <ViewModeSwitcher />
      </div>

      <div className="flex justify-end items-center gap-2">
        <div className="max-lg:hidden">
          <SortControl />
        </div>
        <ProductsPDF supplier={supplier} query={query} filters={<FiltersControls />} lang={lang} />
      </div>
    </div>
  );
};

const FiltersControls = ({ embedded }: { embedded?: boolean }) => {
  const {
    lang,
    brandChoices,
    categoryChoices,
    stockChoices,
    // offerChoices,
    selectedBrands,
    setSelectedBrands,
    selectedCategories,
    setSelectedCategories,
    selectedStock,
    setSelectedStock,
    selectedOffer,
    setSelectedOffer,
  } = useProductsUIData();

  const t = useTranslations(lang);

  return (
    <>
      <div>
        {embedded && <h3 className="font-semibold">{t("products.filters.brands")}</h3>}
        <SelectMultiple
          title={t("products.filters.brands")}
          choices={brandChoices}
          selectedValues={selectedBrands}
          setSelectedValues={setSelectedBrands}
          lang={lang}
          className="w-full"
          embedded={embedded}
          noSearch={brandChoices.length < 8}
        />
      </div>

      <div>
        {embedded && <h3 className="font-semibold">{t("products.filters.categories")}</h3>}
        <SelectMultiple
          title={t("products.filters.categories")}
          choices={categoryChoices}
          selectedValues={selectedCategories}
          setSelectedValues={setSelectedCategories}
          lang={lang}
          className="w-full"
          embedded={embedded}
          noSearch={categoryChoices.length < 8}
        />
      </div>

      <div>
        {embedded && <h3 className="font-semibold mb-1">{t("products.filters.stock")}</h3>}
        {/* <div className="flex items-center space-x-2">
          <Switch
            id="filter-stock"
            checked={selectedStock === "yes"}
            onCheckedChange={(v) => setSelectedStock(v ? "yes" : "all")}
          />
          <Label htmlFor="filter-offer">
            {t("products.filters.stock.yes")}
          </Label>
        </div> */}

        <ComboBoxResponsive
          icon={<PackageIcon className="w-4 h-4" />}
          value={selectedStock}
          onChange={(v) => setSelectedStock(v as ProductsQueryParams["stock"])}
          choices={stockChoices}
          lang={lang}
          noSearch
          className="w-full"
        />
      </div>

      <div>
        {embedded && <h3 className="font-semibold mb-1">{t("products.filters.offer")}</h3>}
        <div className="flex items-center space-x-2">
          <Switch
            id="filter-offer"
            checked={selectedOffer === "yes"}
            onCheckedChange={(v) => setSelectedOffer(v ? "yes" : "all")}
          />
          <Label htmlFor="filter-offer">{t("products.filters.offer.yes")}</Label>
        </div>

        {/* <ComboBoxResponsive
          icon={<TicketPercentIcon className="w-4 h-4" />}
          value={selectedOffer}
          onChange={(v) => setSelectedOffer(v as ProductsQueryParams["offer"])}
          choices={offerChoices}
          lang={lang}
          noSearch
          className="w-full"
        /> */}
      </div>
    </>
  );
};

const ProductsSheet = () => {
  const { lang } = useProductsUIData();
  const t = useTranslations(lang);
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" className="flex gap-2">
          <FilterIcon className="w-4 h-4" />
          <span>{t("common.filter")}</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col" aria-describedby={undefined}>
        <VisuallyHidden>
          <SheetHeader>
            <SheetTitle>{t("common.filter")}</SheetTitle>
          </SheetHeader>
        </VisuallyHidden>

        <div className="grid gap-2 overflow-auto px-6 -mx-6">
          <div>
            <h3 className="font-semibold mb-1">{t("common.sort")}</h3>
            <SortControl />
          </div>
          <FiltersControls embedded />
        </div>
        <SheetClose asChild>
          <Button variant="one" className="mt-auto">
            {t("common.done")}
          </Button>
        </SheetClose>
      </SheetContent>
    </Sheet>
  );
};

const ViewModeSwitcher = () => {
  const { viewMode, setViewMode, lang } = useProductsUIData();
  const t = useTranslations(lang);

  return (
    <div className={tabsListClassName}>
      <button
        type="button"
        onClick={() => setViewMode("list")}
        data-state={viewMode === "list" && "active"}
        className={tabsTriggerClassName}
        aria-label={t("common.viewAs") + " " + t("common.list")}
        title={t("common.viewAs") + " " + t("common.list")}
      >
        <ListIcon className="w-4 h-4" />
      </button>
      <button
        type="button"
        onClick={() => setViewMode("grid")}
        data-state={viewMode === "grid" && "active"}
        className={tabsTriggerClassName}
        aria-label={t("common.viewAs") + " " + t("common.grid")}
        title={t("common.viewAs") + " " + t("common.grid")}
      >
        <GridIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

const SortControl = () => {
  const { lang, selectedSort, setSelectedSort, sortChoices } = useProductsUIData();
  return (
    <ComboBoxResponsive
      value={selectedSort}
      onChange={setSelectedSort}
      choices={sortChoices}
      lang={lang}
      className="w-full"
    />
  );
};

const Footer = () => {
  const { initialLoading, showLoadMore, loadingMore, loadMore, lang } = useProductsUIData();
  const t = useTranslations(lang);

  return (
    <>
      {initialLoading && (
        <div>
          <Loader2Icon className="animate-spin m-auto w-10 h-10" />
        </div>
      )}

      {showLoadMore && (
        <div className="text-center">
          <Button
            variant="outline-two"
            onClick={loadMore}
            className="inline-flex gap-2 text-base"
            size="lg"
          >
            <span>{t("common.loadMore")}</span>
            {loadingMore && <Loader2Icon className="animate-spin m-auto w-4 h-4" />}
          </Button>
        </div>
      )}
    </>
  );
};
